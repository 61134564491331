import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private data: Map<string, unknown> = new Map<string, unknown>();
  private subjectMap: Map<string, Subject<unknown>> = new Map<string, Subject<unknown>>();

  /**
   * Set a value in the state object
   * @param key The key of the data to store
   * @param val The value to store against the key
   */
  set<T>(key: string, val: T): void {
    this.data.set(key, val);

    if (this.subjectMap.has(key)) {
      this.subjectMap.get(key)?.next(val);
    }
  }

  /**
   * Get a value from the state object
   * @param key The key of the data to fetch
   */
  get<T>(key: string): T {
    return this.data.get(key) as T;
  }

  /**
   * Remove a value from the state object
   * @param key The key of the data to remove
   */
  remove(key: string): void {
    this.data.delete(key);
  }

  /**
   * Subscribe to changes for a specific key
   * @param key The key to subscribe to
   */
  watch<T>(key: string): Observable<T> {
    if (!this.subjectMap.has(key)) {
      this.subjectMap.set(key, new Subject<unknown>());
    }
    return this.subjectMap.get(key)?.asObservable() as Observable<T>;
  }
}
