import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ProductCatalogService } from '@yol-digital/ms-client';
import { catchError, EMPTY, map, shareReplay } from 'rxjs';
import { EnvironmentUrlsConfig } from './interfaces/ms-config.interface';
import { ObservableCachedDataLoaderService } from './observable-cached-data-loader.service';
import { ENVIRONMENT_URLS_CONFIG_TOKEN } from './urls.token';
import Api = ProductCatalogService.Api;
import CountriesResponse = ProductCatalogService.CountriesResponse;
import IdTypesResponse = ProductCatalogService.PersonalIdTypeResponse;
export type PaymentTypeEnum = ProductCatalogService.PaymentTypeEnum;

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private http = inject(HttpClient);
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private api: Api;
  private dataLoader = inject(ObservableCachedDataLoaderService);
  public countries: { FR: string; DE: string; EN: string; IT: string; alphaCode: string }[];

  constructor() {
    this.api = new Api(this.config.newMicroServiceEndpoint, this.http);
  }

  getCountries() {
    return this.dataLoader.get('countries', () =>
      this.api.public.getCountries().pipe(
        catchError(() => {
          return EMPTY;
        }),
        map(({ countries }: CountriesResponse) => {
          this.countries = Object.entries(countries).map(([key, value]) => {
            return <{ FR: string; DE: string; EN: string; IT: string; alphaCode: string }>{
              ...value,
              alphaCode: key,
            };
          });
          return this.countries;
        }),
        shareReplay(1)
      )
    );
  }

  getIdTypesByCountry(countryAlphaCode: string, productType: PaymentTypeEnum) {
    return this.dataLoader.get(`idTypes_${countryAlphaCode}`, () =>
      this.api.public.personalIdTypes(countryAlphaCode, productType).pipe(
        catchError(() => {
          return EMPTY;
        }),
        map((response: IdTypesResponse[]) => {
          return response;
        }),
        shareReplay(1)
      )
    );
  }
}
