"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PosCustomerLookupService = void 0;
const Interfaces_1 = require("../Interfaces");
var PosCustomerLookupService;
(function (PosCustomerLookupService) {
  /**
   * @title YOL Service
   * @version 0.1.13
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL POS CUSTOMER LOOKUP SERVICE
   */
  class Api {
    constructor(url, http) {
      this.customer = {
        /**
        * No description
        *
        * @tags Customer
        * @name GetCustomerAccount
        * @summary Provide customer account and owner information
        * @request GET:/pos-customer-lookup/customer/{searchRefId}/account
        * @secure bearerAuth
        * @response `200` `AccountResp` Ok
        * @response `404` `{
        error: "ERR_REFID_NOT_FOUND" | "ERR_CUSTOMER_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_MASK_DATA",
             }`
        */
        getCustomerAccount: (searchRefId, params = {}) => this.http.request("GET", `${this.url}/pos-customer-lookup/customer/${searchRefId}/account`, {
          ...params
        }),
        /**
        * No description
        *
        * @tags Customer
        * @name UpdateContactNumber
        * @request POST:/pos-customer-lookup/customer/{searchRefId}/update/contact-number
        * @secure bearerAuth
        * @response `204` `void` No content
        * @response `404` `{
        error: "ERR_REFID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_UPDATE_CONTACT_NUMBER",
             }`
        */
        updateContactNumber: (searchRefId, data, params = {}) => this.http.request("POST", `${this.url}/pos-customer-lookup/customer/${searchRefId}/update/contact-number`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Customer
        * @name UpdateContactEmail
        * @request POST:/pos-customer-lookup/customer/{searchRefId}/update/email
        * @secure bearerAuth
        * @response `200` `({
        refId?: string,
             })` Ok
        * @response `404` `{
        error: "ERR_REFID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_UPDATE_CONTACT_EMAIL",
             }`
        */
        updateContactEmail: (searchRefId, data, params = {}) => this.http.request("POST", `${this.url}/pos-customer-lookup/customer/${searchRefId}/update/email`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Customer
        * @name UpdateContactBillingAddress
        * @request POST:/pos-customer-lookup/customer/{searchRefId}/update/billing-address
        * @secure bearerAuth
        * @response `204` `void` No content
        * @response `404` `{
        error: "ERR_REFID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_UPDATE_CONTACT_BILLING_ADDRESS",
             }`
        */
        updateContactBillingAddress: (searchRefId, data, params = {}) => this.http.request("POST", `${this.url}/pos-customer-lookup/customer/${searchRefId}/update/billing-address`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Customer
        * @name UpdateContactConfirmEmail
        * @request POST:/pos-customer-lookup/customer/{searchRefId}/update/confirm-email
        * @secure bearerAuth
        * @response `204` `void` No content
        * @response `404` `{
        error: "ERR_REFID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_UPDATE_CONFIRM_EMAIL",
             }`
        */
        updateContactConfirmEmail: (searchRefId, data, params = {}) => this.http.request("POST", `${this.url}/pos-customer-lookup/customer/${searchRefId}/update/confirm-email`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.search = {
        /**
        * No description
        *
        * @tags Search
        * @name SearchByEmailStart
        * @summary Start the search customer process by his/her Email
        * @request POST:/pos-customer-lookup/search/email/start
        * @secure bearerAuth
        * @response `200` `{
        otpRefId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_USE_OTHER_SEARCH_OPTION" | "ERR_INVALID_METHOD",
             }`
        * @response `404` `{
        error: "ERR_ACCOUNT_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_FROM_API" | "ERR_SYSTEM_ERROR",
             }`
        */
        searchByEmailStart: (data, params = {}) => this.http.request("POST", `${this.url}/pos-customer-lookup/search/email/start`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Search
        * @name SearchByPersonalDetails
        * @summary Search Customer by User personal details
        * @request POST:/pos-customer-lookup/search/personal-details
        * @secure bearerAuth
        * @response `200` `SearchByPersonalDetailsRes` Ok
        * @response `400` `{
        error: "ERR_USE_OTHER_SEARCH_OPTION",
             }`
        * @response `404` `{
        error: "ERR_ACCOUNT_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        searchByPersonalDetails: (data, params = {}) => this.http.request("POST", `${this.url}/pos-customer-lookup/search/personal-details`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Search
        * @name SearchByPhoneStart
        * @summary Start the search customer process by his/her phone number
        * @request POST:/pos-customer-lookup/search/phone/start
        * @secure bearerAuth
        * @response `200` `{
        otpRefId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_USE_OTHER_SEARCH_OPTION" | "ERR_INVALID_METHOD",
             }`
        * @response `404` `{
        error: "ERR_ACCOUNT_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_FROM_API" | "ERR_SYSTEM_ERROR",
             }`
        */
        searchByPhoneStart: (data, params = {}) => this.http.request("POST", `${this.url}/pos-customer-lookup/search/phone/start`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Search
        * @name ConfirmPhone
        * @summary Confirm phone number
        * @request POST:/pos-customer-lookup/search/phone/confirm
        * @secure bearerAuth
        * @response `200` `{
        searchRefId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_CODE",
             }`
        * @response `429` `{
        error: "ERR_MAX_REACHED",
             }`
        * @response `500` `{
        error: "ERR_FROM_API" | "ERR_SYSTEM_ERROR",
             }`
        */
        confirmPhone: (data, params = {}) => this.http.request("POST", `${this.url}/pos-customer-lookup/search/phone/confirm`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Search
        * @name ConfirmEmail
        * @summary Confirm email
        * @request POST:/pos-customer-lookup/search/email/confirm
        * @secure bearerAuth
        * @response `200` `{
        searchRefId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_CODE",
             }`
        * @response `429` `{
        error: "ERR_MAX_REACHED",
             }`
        * @response `500` `{
        error: "ERR_FROM_API" | "ERR_SYSTEM_ERROR",
             }`
        */
        confirmEmail: (data, params = {}) => this.http.request("POST", `${this.url}/pos-customer-lookup/search/email/confirm`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  PosCustomerLookupService.Api = Api;
})(PosCustomerLookupService = exports.PosCustomerLookupService || (exports.PosCustomerLookupService = {}));