import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { lang } from 'interfaces';
import { FALLBACK_LANGUAGE } from './browser.token';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  protected document = inject<Document>(DOCUMENT);
  protected fallbackLang = inject<lang>(FALLBACK_LANGUAGE);
  private window: Window;

  constructor() {
    this.window = this.document.defaultView;
  }

  public getDefaultLanguage() {
    return this.getLanguageFromLocale(navigator.language);
  }

  protected getLanguageFromLocale(locale: string) {
    return locale?.split('-')?.[0]?.toLowerCase() || this.fallbackLang;
  }

  /**
   * Returns the Location object's URL's origin (protocol + hostname + port).
   * @returns
   */
  public getOrigin() {
    return this.document.location.origin;
  }

  /**
   * Returns the Location object's URL's host and port (if different from the default port for the scheme).
   * @returns
   */
  public getHost() {
    return this.document.location.host;
  }

  public matchMedia(mediaQueryString: string) {
    if (this.window) {
      return this.window.matchMedia(mediaQueryString).matches;
    }

    return false;
  }

  public redirect(url: string): void {
    this.window.location.href = url;
  }

  public openUrlInNewTab(url: string, windowName?: string) {
    this.window.open(url, windowName ?? '_blank');
  }

  public reload(): void {
    this.window.location.reload();
  }

  public getPathWithoutLanguage = () => {
    return this.document.location.pathname.substring(3) || '/';
  };
}
