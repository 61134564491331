import { MatDateFormats } from '@angular/material/core';

export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_MONTH_YEAR_LABEL_FORMAT = 'MMM yyyy';
export const DATE_A11Y_LABEL_FORMAT = 'LL';
export const DATE_MONTH_YEAR_A11Y_LABEL_FORMAT = 'MMMM yyyy';
export const DATE_MS_FORMAT = 'yyyy-MM-dd';
export const SUPPORTED_LANGUAGES = { de: 'de-ch', en: 'en-gb', fr: 'fr-ch', it: 'it-ch' };
export const AUTO_TEST_URL = 'AUTO_TEST_URL';
export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: DATE_FORMAT,
  },
  display: {
    dateInput: DATE_FORMAT,
    monthYearLabel: DATE_MONTH_YEAR_LABEL_FORMAT,
    dateA11yLabel: DATE_A11Y_LABEL_FORMAT,
    monthYearA11yLabel: DATE_MONTH_YEAR_A11Y_LABEL_FORMAT,
  },
};
