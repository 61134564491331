"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelfcarePassService = void 0;
const Interfaces_1 = require("../Interfaces");
var SelfcarePassService;
(function (SelfcarePassService) {
  /**
   * @title YOL Service
   * @version 1.0.20
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL SELFCARE PASS MICROSERVICE
   */
  class Api {
    constructor(url, http) {
      this.public = {
        /**
        * No description
        *
        * @tags public
        * @name ChangetPassword
        * @summary request for change password against account id
        * @request POST:/selfcare-pass/change-password
        * @secure bearerAuth
        * @response `204` `void` No content
        * @response `400` `{
        error: "ERR_BAD_REQUEST" | "ERR_PASSWORD_NOT_MATCHED" | "ERR_PASSWORD_MATCH_OLD_PASSWOARD",
             }`
        * @response `401` `{
        error: "ERR_MISSING_TOKEN",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        changetPassword: (data, params = {}) => this.http.request("POST", `${this.url}/selfcare-pass/change-password`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name SetPassword
        * @summary generate password against account id and and store in hash form
        * @request POST:/selfcare-pass/set-password
        * @secure bearerAuth
        * @response `204` `void` No content
        * @response `400` `{
        error: "ERR_PASSWORD_ALREADY_FOUND",
             }`
        * @response `401` `{
        error: "ERR_MISSING_TOKEN",
             }`
        * @response `500` `{
        error: "ERR_FROM_API",
             }`
        */
        setPassword: (data, params = {}) => this.http.request("POST", `${this.url}/selfcare-pass/set-password`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name RequestResetPassword
        * @summary request reset password
        * @request POST:/selfcare-pass/request-reset-password
        * @response `204` `void` No content
        * @response `400` `{
        error: "ERR_BAD_REQUEST" | "ERR_MULTIPLE_ACCOUNT_FOUND",
             }`
        * @response `404` `{
        error: "ERR_ACCOUNT_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        requestResetPassword: (data, params = {}) => this.http.request("POST", `${this.url}/selfcare-pass/request-reset-password`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name ResetPassword
        * @summary set new password against account id
        * @request POST:/selfcare-pass/reset-password
        * @response `204` `void` No content
        * @response `400` `{
        error: "ERR_BAD_REQUEST" | "ERR_LINK_ALREADY_EXPIRED_OR_WRONG",
             }`
        * @response `404` `{
        error: "ERR_ACCOUNT_NOT_FOUND",
             }`
        * @response `429` `{
        error: "ERR_MAX_REACHED",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        resetPassword: (data, params = {}) => this.http.request("POST", `${this.url}/selfcare-pass/reset-password`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name ValidateResetPasswordRequest
        * @summary check if reset password ref id exists
        * @request GET:/selfcare-pass/validate-reset-password/{refId}
        * @response `204` `void` No content
        * @response `400` `{
        error: "ERR_LINK_ALREADY_EXPIRED_OR_WRONG",
             }`
        * @response `429` `{
        error: "ERR_MAX_REACHED",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        validateResetPasswordRequest: (refId, params = {}) => this.http.request("GET", `${this.url}/selfcare-pass/validate-reset-password/${refId}`, {
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  SelfcarePassService.Api = Api;
})(SelfcarePassService = exports.SelfcarePassService || (exports.SelfcarePassService = {}));