import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ObservableCachedDataLoaderService {
  private subjects: Map<string, ReplaySubject<unknown>> = new Map();

  /**
   * Gets the data either from the cache (observable) or if not
   * found executes the function and updates the ReplaySubject.
   * @param key identifies the data in the cache
   * @param fn function to load the data
   * @returns the loaded data
   */
  get<T>(key: string, fn: () => Observable<T>): Observable<T> {
    let subject: ReplaySubject<T>;

    if (!this.subjects.has(key)) {
      subject = new ReplaySubject<T>(1);
      this.subjects.set(key, subject);

      // Start loading the data and push it to the subject once done
      fn()
        .pipe(
          take(1), // Only need to get data once
          tap(data => {
            if (data !== undefined && data !== null) {
              subject.next(data);
            } else {
              this.subjects.delete(key); // Remove the cache entry if data is null
              subject.next(data); // Still emit the null value
            }
            subject.complete();
          }),
          catchError(err => {
            this.subjects.delete(key);
            subject.error(err);
            return of(undefined);
          })
        )
        .subscribe();
    } else {
      subject = this.subjects.get(key) as ReplaySubject<T>;
    }

    return subject.asObservable();
  }

  set<T>(key: string, value: T): void {
    const subject = new ReplaySubject<T>(1);
    this.subjects.set(key, subject);
    subject.next(value);
  }

  /**
   * Invalidates data in the cache by setting its value to null
   * @param key string
   */
  remove(key: string): void {
    this.subjects.delete(key);
  }

  /**
   * Invalidates any data in the cache where the key contains the key passed in
   * @param key string used for fuzzy matching
   */
  removeFuzzy(key: string): void {
    const keys = Array.from(this.subjects.keys()).filter(_key => _key.includes(key));
    keys.forEach(_key => this.remove(_key));
  }

  /**
   * Clears the cache
   */
  clear(): void {
    this.subjects.clear();
  }
}
