"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContentType = void 0;
var ContentType;
(function (ContentType) {
  ContentType["Json"] = "application/json";
  ContentType["FormData"] = "multipart/form-data";
  ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
  ContentType["Text"] = "text/plain";
})(ContentType = exports.ContentType || (exports.ContentType = {}));