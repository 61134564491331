/**
 *
 * @param src The Javascript source for <script src="`src`">
 * @param location Where to append the script to, the HTML `<head>` or `<body>`
 */

export function appendScript(
  document: Document,
  src: string,
  defer = true,
  async = true,
  location: 'head' | 'body' = 'body'
) {
  if (!document) {
    return;
  }
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  (script.defer = defer), (script.async = async);
  switch (location) {
    case 'head':
      document.head.appendChild(script);
      break;
    case 'body':
      document.body.appendChild(script);
  }
}
