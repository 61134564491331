import { inject, Injectable, makeStateKey, StateKey, TransferState } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TransferStateService {
  private transferState = inject(TransferState);

  /**
   * Wrapper function to handle transfer state. If they given key is found, its value is returned.
   * If not, the function is executed and the result stored in the transfer state.
   * @param key unique key to identify the entry in the state transfer
   * @param fn a function with no arguments that is executed if the key is not found
   */
  async withStateTransfer<T>(key: string, fn: () => Promise<T>): Promise<T> {
    const KEY = <StateKey<T>>makeStateKey(key);
    let resp: T;
    if (this.transferState.hasKey(KEY)) {
      resp = this.transferState.get<T>(KEY, null);
    } else {
      resp = await fn();
      this.transferState.set<T>(KEY, resp);
    }
    return resp;
  }
}
