import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  private meta = inject(Meta);
  private title = inject(Title);
  private document = inject<Document>(DOCUMENT);

  setDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  setTitle(title: string) {
    this.title.setTitle(title);
  }

  setRobotsNoIndex(hide: boolean) {
    this.meta.removeTag('name=robots');

    if (hide) {
      this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
    }
  }
}
