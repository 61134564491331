"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderHandlerService = void 0;
var OrderHandlerService;
(function (OrderHandlerService) {
  /**
   * @title YOL Service
   * @version 1.0.114
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL ORDER HANDLER MICROSERVICE
   */
  class Api {
    constructor(url, http) {
      this.public = {
        /**
        * No description
        *
        * @tags public
        * @name RetrieveOrderStatus
        * @request GET:/order-handler/order-status/{checkoutSessionId}
        * @response `200` `OrderStatusRes` Ok
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        retrieveOrderStatus: (checkoutSessionId, params = {}) => this.http.request("GET", `${this.url}/order-handler/order-status/${checkoutSessionId}`, {
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  OrderHandlerService.Api = Api;
})(OrderHandlerService = exports.OrderHandlerService || (exports.OrderHandlerService = {}));