import { AUTO_TEST_URL } from '../app.constants';
import { EnvironmentUrlsConfig } from '../interfaces/ms-config.interface';

const getValidEnvironment = (origin: string, isMs: boolean, allowedMsEnvs: string[]): string => {
  const env = origin
    .split('://')[1]
    .split('.')[0]
    .replace(/-checkout|-pos|-my/i, '');
  if (!isMs || allowedMsEnvs.includes(env)) return env;
  return undefined;
};

export const calcEnvironmentUrls = (
  environment: {
    newMicroServiceEndpoint?: string;
    websiteUrl?: string;
    checkoutUrl?: string;
    posUrl?: string;
    selfcareUrl?: string;
    defaultUrlsEnvironment: string;
    allowedMsEnvs: string[];
    yalloTvUrl?: string;
    oldEshopLink?: string;
  },
  origin: string
): EnvironmentUrlsConfig => {
  const brand = origin.includes('lebara') ? 'lebara' : 'yallo';
  const msEnv = getValidEnvironment(origin, true, environment.allowedMsEnvs) || environment.defaultUrlsEnvironment;
  const env = getValidEnvironment(origin, false, environment.allowedMsEnvs) || environment.defaultUrlsEnvironment;

  return {
    newMicroServiceEndpoint:
      environment.newMicroServiceEndpoint === AUTO_TEST_URL
        ? // msEnv === 'preprod'
          //   ? `https://${msEnv}.t.api.${brand}.ch` :
          `https://${msEnv}-ms.dev.${brand}.ch`
        : environment.newMicroServiceEndpoint,
    websiteUrl: environment.websiteUrl !== AUTO_TEST_URL ? environment.websiteUrl : `https://${env}.dev.${brand}.ch`,
    checkoutUrl:
      environment.checkoutUrl !== AUTO_TEST_URL ? environment.checkoutUrl : `https://${env}-checkout.dev.${brand}.ch`,
    posUrl: environment.posUrl !== AUTO_TEST_URL ? environment.posUrl : `https://${env}-pos.dev.${brand}.ch`,
    selfcareUrl:
      environment.selfcareUrl !== AUTO_TEST_URL ? environment.selfcareUrl : `https://${env}-my.dev.${brand}.ch`,
    yalloTvUrl: environment.yalloTvUrl,
    oldEshopLink: environment.oldEshopLink,
  };
};
