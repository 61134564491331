export const initGoogleTagManager = (gtmId: string) => {
  const script = document.createElement('script');
  script.innerHTML = `
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer', '${gtmId}');
	`;

  document.head.appendChild(script);
};

export const appendNoScriptGoogleTagManager = (document: Document, gtmId: string) => {
  const noScript = document.createElement('noscript');
  noScript.innerHTML = `
  <!-- GTM noscript tag -->
    <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;
  document.body.appendChild(noScript);
};
