import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currencyFormatter', standalone: true })
export class CurrencyFormatterPipe implements PipeTransform {
  transform(input: string | number, noCurrency = true, noDotDash?: boolean): string {
    if (!input) return noCurrency ? '0.–' : 'CHF 0.–';
    const amount = +input;
    let output = '';
    if (!noCurrency) output += 'CHF ';
    if (amount % 1 != 0) {
      output += amount.toFixed(2);
    } else {
      if (noDotDash) {
        output += amount;
      } else {
        output += `${amount}.–`; //todo  "&mdash;"
      }
    }
    return output;
  }
}
