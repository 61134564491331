"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TvSignupService = void 0;
const Interfaces_1 = require("../Interfaces");
var TvSignupService;
(function (TvSignupService) {
  /**
   * @title YOL Service
   * @version 1.0.56
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL TV SIGNUP MICROSERVICE
   */
  class Api {
    constructor(url, http) {
      this.public = {
        /**
        * No description
        *
        * @tags public
        * @name DoFreeTvSignup
        * @summary Do Free TV Subscription
        * @request POST:/tv-signup/doFreeTvSignup
        * @response `200` `FreeTvSubscriptionDTO` Ok
        * @response `400` `{
        error: "ERR_EMAIL_ALREADY_HAS_PAID_TV_VERSION" | "ERR_EMAIL_ADDRESS_ALREADY_TAKEN",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        doFreeTvSignup: (data, params = {}) => this.http.request("POST", `${this.url}/tv-signup/doFreeTvSignup`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name TerminateSubscription
        * @summary terminate-account
        * @request POST:/tv-signup/terminate-account
        * @secure api_key
        * @response `204` `void` No content
        * @response `400` `{
        error: "ERR_ACCOUNT_HAS_ALREADY_BEEN_TERMINATED",
             }`
        * @response `404` `{
        error: "ERR_ACCOUNT_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        terminateSubscription: (data, params = {}) => this.http.request("POST", `${this.url}/tv-signup/terminate-account`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  TvSignupService.Api = Api;
})(TvSignupService = exports.TvSignupService || (exports.TvSignupService = {}));