"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreditCheckService = void 0;
const Interfaces_1 = require("../Interfaces");
var CreditCheckService;
(function (CreditCheckService) {
  /**
   * @title YOL Service
   * @version 1.0.122
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL CREDIT CHECK SERVICE
   */
  class Api {
    constructor(url, http) {
      this.eshop = {
        /**
        * No description
        *
        * @tags eshop
        * @name EshopDoCreditCheck
        * @summary Do credit check from online
        * @request POST:/creditcheck/eshop/do-credit-score
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN" | "ERR_INVALID_CREDIT_CHECK_DATA" | "ERR_CREDITCHECK_BAD_CUSTOMER" | "ERR_CREDITCHECK_ALLOWED_SUB_EXCEEDED" | "ERR_CREDITCHECK_LIGHT_PRODUCT_ONLY" | "ERR_CREDITCHECK_MANUAL_CHECK_REQUIRED",
             }`
        * @response `404` `{
        error: "ERR_CHECKOUT_SESSION_ID_NOT_FOUND" | "ERR_CUSTOMER_DETAILS_EMPTY" | "ERR_PRODUCT_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_FROM_CRM" | "ERR_CHECKOUT_SERVICE_UNAVAILABLE",
             }`
        * @response `503` `{
        error: "ERR_CHECKOUT_SERVICE_READ_TIMEOUT",
             }`
        */
        eshopDoCreditCheck: (data, params = {}) => this.http.request("POST", `${this.url}/creditcheck/eshop/do-credit-score`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.pos = {
        /**
        * No description
        *
        * @tags pos
        * @name DoCreditCheck
        * @summary Do credit check from POS
        * @request POST:/creditcheck/pos/do-credit-score
        * @secure bearerAuth
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN" | "ERR_INVALID_CREDIT_CHECK_DATA" | "ERR_CREDITCHECK_BAD_CUSTOMER" | "ERR_CREDITCHECK_ALLOWED_SUB_EXCEEDED" | "ERR_CREDITCHECK_LIGHT_PRODUCT_ONLY" | "ERR_CREDITCHECK_MANUAL_CHECK_REQUIRED",
             }`
        * @response `404` `{
        error: "ERR_CHECKOUT_SESSION_ID_NOT_FOUND" | "ERR_CUSTOMER_DETAILS_EMPTY" | "ERR_PRODUCT_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_FROM_CRM" | "ERR_CHECKOUT_SERVICE_UNAVAILABLE",
             }`
        * @response `503` `{
        error: "ERR_CHECKOUT_SERVICE_READ_TIMEOUT",
             }`
        */
        doCreditCheck: (data, params = {}) => this.http.request("POST", `${this.url}/creditcheck/pos/do-credit-score`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  CreditCheckService.Api = Api;
})(CreditCheckService = exports.CreditCheckService || (exports.CreditCheckService = {}));