import { ActivatedRouteSnapshot } from '@angular/router';

const getPath = (route: ActivatedRouteSnapshot): string[] => {
  let path: string[] = route.url.map(u => u.path);
  if (route.firstChild) {
    const childPath = getPath(route.firstChild);
    if (childPath) path = path.concat(...childPath);
  }
  return path;
};

export const routeSnapshotToString = (route: ActivatedRouteSnapshot, includeQueryParams = true) => {
  let queryString = '?';
  for (const key in route.queryParams) queryString += key + '=' + route.queryParams[key] + '&';
  queryString = queryString.substring(0, queryString.length - 1);

  const root = route.root;
  let path = `/${getPath(root).join('/')}`;
  if (path === '/') path = '';

  // If generating a URL for a UrlTree it's best to exclude the query params otherwise they are treated
  // as a part of the path string.
  if (includeQueryParams) {
    return `${path}${queryString}`;
  }
  return path;
};
