"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IdentityService = void 0;
const Interfaces_1 = require("../Interfaces");
var IdentityService;
(function (IdentityService) {
  /**
   * @title YOL Service
   * @version 1.0.184
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL IDENTITY SERVICE
   */
  class Api {
    constructor(url, http) {
      this.gdpr = {
        /**
        * No description
        *
        * @tags Gdpr
        * @name Login
        * @request POST:/identity/gdpr/login
        * @response `200` `SecretCodeResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `429` `{
        error: "ERR_TOO_MANY_CODE_REQUESTS",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        login: (data, params = {}) => this.http.request("POST", `${this.url}/identity/gdpr/login`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Gdpr
        * @name Validate
        * @request POST:/identity/gdpr/validate
        * @response `200` `AccessTokenResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `401` `{
        error: "ERR_INVALID_SECRET_OR_CODE",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        validate: (data, params = {}) => this.http.request("POST", `${this.url}/identity/gdpr/validate`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Gdpr
        * @name Logout
        * @request POST:/identity/gdpr/logout
        * @response `204` `void` No content
        * @response `400` `{
        error: "ERR_INVALID_REQUEST",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        logout: (data, params = {}) => this.http.request("POST", `${this.url}/identity/gdpr/logout`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Gdpr
        * @name RefreshToken
        * @request POST:/identity/gdpr/refresh-token
        * @response `200` `AccessTokenResp` Ok
        * @response `401` `{
        error: "ERR_MISSING_REFRESH_TOKEN_COOKIE",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        refreshToken: (params = {}) => this.http.request("POST", `${this.url}/identity/gdpr/refresh-token`, {
          ...params
        })
      };
      this.pos = {
        /**
        * No description
        *
        * @tags Pos
        * @name Token
        * @request POST:/identity/pos/token
        * @response `200` `AccessTokenResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        token: (data, params = {}) => this.http.request("POST", `${this.url}/identity/pos/token`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Pos
        * @name Login
        * @request POST:/identity/pos/login
        * @response `200` `PosSecretCodeResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `401` `{
        error: "ERR_INVALID_USERNAME_OR_PASSWORD",
             }`
        * @response `429` `{
        error: "ERR_TOO_MANY_CODE_REQUESTS",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        login: (data, params = {}) => this.http.request("POST", `${this.url}/identity/pos/login`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Pos
        * @name Validate
        * @request POST:/identity/pos/validate
        * @response `200` `AccessTokenResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `401` `{
        error: "ERR_INVALID_SECRET_OR_CODE" | "ERR_POS_AGENT_LOCKED",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        validate: (data, params = {}) => this.http.request("POST", `${this.url}/identity/pos/validate`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Pos
        * @name Logout
        * @request POST:/identity/pos/logout
        * @response `204` `void` No content
        * @response `400` `{
        error: "ERR_INVALID_REQUEST",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        logout: (data, params = {}) => this.http.request("POST", `${this.url}/identity/pos/logout`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Pos
        * @name RefreshToken
        * @request POST:/identity/pos/refresh-token
        * @response `200` `AccessTokenResp` Ok
        * @response `401` `{
        error: "ERR_MISSING_REFRESH_TOKEN_COOKIE",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        refreshToken: (params = {}) => this.http.request("POST", `${this.url}/identity/pos/refresh-token`, {
          ...params
        }),
        /**
        * No description
        *
        * @tags Pos
        * @name LoginToken
        * @request GET:/identity/pos/login/token/{token}
        * @response `302` `void` REDIRECT
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        loginToken: (token, params = {}) => this.http.request("GET", `${this.url}/identity/pos/login/token/${token}`, {
          ...params
        })
      };
      this.selfcare = {
        /**
        * No description
        *
        * @tags Selfcare
        * @name Login
        * @request POST:/identity/selfcare/login
        * @response `200` `SecretCodeResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN" | "ERR_INVALID_MSISDN_FORMAT" | "ERR_INVALID_EMAIL" | "ERR_INVALID_MSISDN" | "ERR_PASSWORD_REQUIRED",
             }`
        * @response `429` `{
        error: "ERR_TOO_MANY_CODE_REQUESTS",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        login: (data, params = {}) => this.http.request("POST", `${this.url}/identity/selfcare/login`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Selfcare
        * @name Token
        * @request POST:/identity/selfcare/token
        * @response `200` `AccessTokenResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `401` `{
        error: "ERR_INVALID_TOKEN",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        token: (data, params = {}) => this.http.request("POST", `${this.url}/identity/selfcare/token`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Selfcare
        * @name Validate
        * @request POST:/identity/selfcare/validate
        * @response `200` `AccessTokenResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `401` `{
        error: "ERR_INVALID_SECRET_OR_CODE",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        validate: (data, params = {}) => this.http.request("POST", `${this.url}/identity/selfcare/validate`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Selfcare
        * @name Logout
        * @request POST:/identity/selfcare/logout
        * @response `204` `void` No content
        * @response `400` `{
        error: "ERR_INVALID_REQUEST",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        logout: (data, params = {}) => this.http.request("POST", `${this.url}/identity/selfcare/logout`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Selfcare
        * @name RefreshToken
        * @request POST:/identity/selfcare/refresh-token
        * @response `200` `AccessTokenResp` Ok
        * @response `401` `{
        error: "ERR_MISSING_REFRESH_TOKEN_COOKIE",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        refreshToken: (params = {}) => this.http.request("POST", `${this.url}/identity/selfcare/refresh-token`, {
          ...params
        })
      };
      this.tv = {
        /**
        * No description
        *
        * @tags Tv
        * @name Login
        * @request POST:/identity/tv/login
        * @response `200` `SecretCodeResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `429` `{
        error: "ERR_TOO_MANY_CODE_REQUESTS",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        login: (data, params = {}) => this.http.request("POST", `${this.url}/identity/tv/login`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Tv
        * @name Validate
        * @request POST:/identity/tv/validate
        * @response `200` `AccessTokenResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `401` `{
        error: "ERR_INVALID_SECRET_OR_CODE",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        validate: (data, params = {}) => this.http.request("POST", `${this.url}/identity/tv/validate`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags Tv
        * @name Logout
        * @request POST:/identity/tv/logout
        * @response `204` `void` No content
        * @response `400` `{
        error: "ERR_INVALID_REQUEST",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        logout: (data, params = {}) => this.http.request("POST", `${this.url}/identity/tv/logout`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  IdentityService.Api = Api;
})(IdentityService = exports.IdentityService || (exports.IdentityService = {}));