"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FetchClient = exports.FetchContentType = void 0;
var FetchContentType;
(function (FetchContentType) {
  FetchContentType["Json"] = "application/json";
  FetchContentType["FormData"] = "multipart/form-data";
  FetchContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
  FetchContentType["Text"] = "text/plain";
})(FetchContentType = exports.FetchContentType || (exports.FetchContentType = {}));
class FetchClient {
  constructor(apiConfig = {}) {
    this.baseUrl = "/";
    this.securityData = null;
    this.abortControllers = new Map();
    this.baseApiParams = {
      credentials: "same-origin",
      headers: {},
      redirect: "follow",
      referrerPolicy: "no-referrer"
    };
    this.contentFormatters = {
      [FetchContentType.Json]: input => input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
      [FetchContentType.Text]: input => input !== null && typeof input !== "string" ? JSON.stringify(input) : input,
      [FetchContentType.FormData]: input => Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(key, property instanceof Blob ? property : typeof property === "object" && property !== null ? JSON.stringify(property) : `${property}`);
        return formData;
      }, new FormData()),
      [FetchContentType.UrlEncoded]: input => this.toQueryString(input)
    };
    this.customFetch = (...fetchParams) => fetch(...fetchParams);
    this.setSecurityData = data => {
      this.securityData = data;
      return this;
    };
    this.createAbortSignal = cancelToken => {
      if (this.abortControllers.has(cancelToken)) {
        const abortController = this.abortControllers.get(cancelToken);
        if (abortController) {
          return abortController.signal;
        }
        return void 0;
      }
      const abortController = new AbortController();
      this.abortControllers.set(cancelToken, abortController);
      return abortController.signal;
    };
    this.abortRequest = cancelToken => {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        abortController.abort();
        this.abortControllers.delete(cancelToken);
      }
    };
    this.request = async ({
      body,
      secure,
      path,
      type,
      query,
      format,
      baseUrl,
      cancelToken,
      ...params
    }) => {
      const secureParams = (typeof secure === "boolean" ? secure : this.baseApiParams.secure) && this.securityWorker && (await this.securityWorker(path, this.securityData)) || {};
      const requestParams = this.mergeRequestParams(params, secureParams);
      const queryString = query && this.toQueryString(query);
      const payloadFormatter = this.contentFormatters[type || FetchContentType.Json];
      const responseFormat = format || requestParams.format;
      return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
        ...requestParams,
        headers: {
          ...(type && type !== FetchContentType.FormData ? {
            "Content-Type": type
          } : {}),
          ...(requestParams.headers || {})
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
        body: typeof body === "undefined" || body === null ? null : payloadFormatter(body)
      }).then(async response => {
        const r = response;
        r.data = null;
        r.error = null;
        const data = !responseFormat ? r : await response[responseFormat]().then(data => {
          if (r.ok) {
            r.data = data;
          } else {
            r.error = data;
          }
          return r;
        }).catch(e => {
          r.error = e;
          return r;
        });
        if (cancelToken) {
          this.abortControllers.delete(cancelToken);
        }
        return data;
      });
    };
    Object.assign(this, apiConfig);
  }
  encodeQueryParam(key, value) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }
  addQueryParam(query, key) {
    return this.encodeQueryParam(key, query[key]);
  }
  addArrayQueryParam(query, key) {
    const value = query[key];
    return value.map(v => this.encodeQueryParam(key, v)).join("&");
  }
  toQueryString(rawQuery) {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(key => "undefined" !== typeof query[key]);
    return keys.map(key => Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)).join("&");
  }
  addQueryParams(rawQuery) {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }
  mergeRequestParams(params1, params2) {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...(params2 && params2.headers || {})
      }
    };
  }
}
exports.FetchClient = FetchClient;