import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { fromEvent, map, merge, Observable, of, race, retry, Subscription, switchMap, timer } from 'rxjs';
import { randomString } from './helpers/random-string';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private platformId = inject(PLATFORM_ID);
  private networkStatus$: Subscription;
  public networkStatus: boolean;
  private http = inject(HttpClient);
  public online$ = race([
    new Observable(observer => {
      const onlineHandler = () => {
        observer.next(true);
        observer.complete();
      };
      if (isPlatformServer(this.platformId)) {
        return onlineHandler();
      }
      if (window.navigator.onLine) return onlineHandler();
      window.addEventListener('online', onlineHandler, { passive: true });
      return () => window.removeEventListener('online', onlineHandler);
    }),
    timer(10_000).pipe(switchMap(() => this.http.head(`/resources/network-test.json?cachebust=${randomString(12)}`))),
  ]).pipe(
    retry(),
    map(() => true)
  );

  public checkNetworkStatus() {
    if (isPlatformBrowser(this.platformId)) {
      this.networkStatus = navigator.onLine;
      this.networkStatus$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
        .pipe(map(() => navigator.onLine))
        .subscribe(status => {
          this.networkStatus = status;
        });
    }
  }

  public removeNetworkSubscription() {
    if (this.networkStatus$ && !this.networkStatus$.closed) {
      this.networkStatus$.unsubscribe();
    }
  }
}
