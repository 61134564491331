import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CachedDataLoaderService {
  private data: Map<string, unknown> = new Map();
  private loadingPromises: Map<string, Promise<unknown>> = new Map();

  /**
   * Gets the data either from the store, or if not
   * found executes the function and adds the result to
   * the store.
   * If the loading function is executed, the resulting promise
   * is stored as well, and this promise is used in subsequent calls.
   * @param key identifies the data in the store
   * @param fn function to load the data
   * @returns the loaded data
   */
  async get<T>(key: string, fn: () => Promise<T>): Promise<T> {
    if (this.data.has(key)) return <T>this.data.get(key);
    if (this.loadingPromises.has(key)) return <T>await this.loadingPromises.get(key);

    const promise = fn();
    this.loadingPromises.set(key, promise);

    try {
      const resp = await promise;
      if (resp !== undefined && resp !== null) this.data.set(key, resp);
      this.loadingPromises.delete(key);
      return resp;
    } catch (err) {
      this.loadingPromises.delete(key);
      throw err;
    }
  }

  set(key: string, value: unknown) {
    this.data.set(key, value);
  }

  /**
   * Removes data from the cache
   * @param key string
   */
  async remove(key: string) {
    this.data.delete(key);
    this.loadingPromises.delete(key);
  }

  /**
   * Removes any data from the cache where the key contains the key passed in
   * @param key string used for fuzzy matching
   */
  async removeFuzzy(key: string) {
    const keys = Array.from(this.data.keys()).filter(_key => _key.includes(key));
    keys.forEach(_key => {
      this.data.delete(_key);
      this.loadingPromises.delete(_key);
    });
  }

  /**
   * Clears the cache, including any un resolved promises
   */
  clear() {
    this.data.clear();
    this.loadingPromises.clear();
  }
}
