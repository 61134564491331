import { Injectable } from '@angular/core';
import { StorageKeys } from './enums/storage-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private errorMessage = 'Storage is not available in this browser.';

  private getStorageInstance(sessionOnly: boolean): Storage | null {
    try {
      const key = '__test_key__';
      const storage = sessionOnly ? sessionStorage : localStorage;
      storage.setItem(key, null);
      storage.removeItem(key);
      return storage;
    } catch {
      return null;
    }
  }

  public set<T>(name: StorageKeys, data: T, sessionOnly = false, timeKey = 'time') {
    const storage = this.getStorageInstance(sessionOnly);
    if (storage) {
      const storageItem = JSON.stringify({ [timeKey]: Date.now(), data });
      storage.setItem(name, storageItem);
    } else {
      console.info(this.errorMessage);
    }
  }

  public get<T>(name: StorageKeys, maxAge: number = undefined): T | null {
    const localStorage = this.getStorageInstance(false);
    const sessionStorage = this.getStorageInstance(true);

    if (!localStorage && !sessionStorage) {
      console.info(this.errorMessage);
      return null;
    }

    const raw = localStorage?.getItem(name) || sessionStorage?.getItem(name);
    const data = JSON.parse(raw);
    if (!data) return null;
    if (maxAge !== undefined && Date.now() - data.time > maxAge) return null;
    return data.data as T;
  }

  public remove(name: StorageKeys) {
    const localStorage = this.getStorageInstance(false);
    const sessionStorage = this.getStorageInstance(true);

    if (!localStorage && !sessionStorage) {
      console.info(this.errorMessage);
      return;
    }

    if (localStorage) {
      localStorage.removeItem(name);
    }

    if (sessionStorage) {
      sessionStorage.removeItem(name);
    }
  }
}
