"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddressLookupService = void 0;
const Interfaces_1 = require("../Interfaces");
var AddressLookupService;
(function (AddressLookupService) {
  /**
   * @title YOL Service
   * @version 1.0.73
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL ADDRESS LOOKUP MICROSERVICE
   */
  class Api {
    constructor(url, http) {
      this.public = {
        /**
        * No description
        *
        * @tags public
        * @name City
        * @summary Address lookup via city
        * @request POST:/address-lookup/city
        * @response `200` `AddressLookupResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_RECORD_NOT_FOUND" | "ERR_NUTE_API_ADDRESS_NOT_VALID" | "ERR_SWISS_API_ADDRESS_NOT_VALID",
             }`
        * @response `500` `{
        error: "ERR_SWISS_POST_API" | "ERR_NUTE_API_SERVICE_UNAVAILABLE" | "ERR_NUTE_API" | "ERR_SWISS_API_SERVICE_UNAVAILABLE" | "ERR_NUTE_SWISS_API_SERVICE_UNAVAILABLE",
             }`
        * @response `503` `{
        error: "ERR_NUTE_API_TIMEOUT" | "ERR_SWISS_API_TIMEOUT",
             }`
        */
        city: (data, params = {}) => this.http.request("POST", `${this.url}/address-lookup/city`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name Street
        * @summary Address lookup via street
        * @request POST:/address-lookup/street
        * @response `200` `AddressLookupResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_RECORD_NOT_FOUND" | "ERR_NUTE_API_ADDRESS_NOT_VALID" | "ERR_SWISS_API_ADDRESS_NOT_VALID",
             }`
        * @response `500` `{
        error: "ERR_SWISS_POST_API" | "ERR_NUTE_API_SERVICE_UNAVAILABLE" | "ERR_NUTE_API" | "ERR_SWISS_API_SERVICE_UNAVAILABLE" | "ERR_NUTE_SWISS_API_SERVICE_UNAVAILABLE",
             }`
        * @response `503` `{
        error: "ERR_NUTE_API_TIMEOUT" | "ERR_SWISS_API_TIMEOUT",
             }`
        */
        street: (data, params = {}) => this.http.request("POST", `${this.url}/address-lookup/street`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name StreetNumber
        * @summary Address lookup via street number
        * @request POST:/address-lookup/street-number
        * @response `200` `AddressLookupResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_RECORD_NOT_FOUND" | "ERR_NUTE_API_ADDRESS_NOT_VALID" | "ERR_SWISS_API_ADDRESS_NOT_VALID",
             }`
        * @response `500` `{
        error: "ERR_SWISS_POST_API" | "ERR_NUTE_API_SERVICE_UNAVAILABLE" | "ERR_NUTE_API" | "ERR_SWISS_API_SERVICE_UNAVAILABLE" | "ERR_NUTE_SWISS_API_SERVICE_UNAVAILABLE",
             }`
        * @response `503` `{
        error: "ERR_NUTE_API_TIMEOUT" | "ERR_SWISS_API_TIMEOUT",
             }`
        */
        streetNumber: (data, params = {}) => this.http.request("POST", `${this.url}/address-lookup/street-number`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  AddressLookupService.Api = Api;
})(AddressLookupService = exports.AddressLookupService || (exports.AddressLookupService = {}));