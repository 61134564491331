"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PdfService = void 0;
const Interfaces_1 = require("../Interfaces");
var PdfService;
(function (PdfService) {
  /**
   * @title YOL Service
   * @version 1.0.40
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL PDF MICROSERVICE
   */
  class Api {
    constructor(url, http) {
      this.eshop = {
        /**
        * No description
        *
        * @tags eshop
        * @name GenerateContract
        * @summary ESHOP - Generate Contract PDF
        * @request POST:/pdf/eshop/generate-contract
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_JSON_VALIDATION" | "ERR_CUSTOMER_DETAILS_NOT_CONFIRMED",
             }`
        * @response `404` `{
        error: "ERR_TEMPLATE_ID_NOT_FOUND" | "ERR_CHECKOUT_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        generateContract: (data, params = {}) => this.http.request("POST", `${this.url}/pdf/eshop/generate-contract`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name GeneratePoa
        * @summary ESHOP - Generate POA PDF
        * @request POST:/pdf/eshop/generate-poa
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_JSON_VALIDATION" | "ERR_CUSTOMER_DETAILS_NOT_CONFIRMED",
             }`
        * @response `404` `{
        error: "ERR_TEMPLATE_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        generatePoa: (data, params = {}) => this.http.request("POST", `${this.url}/pdf/eshop/generate-poa`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.pos = {
        /**
        * No description
        *
        * @tags pos
        * @name GenerateContract
        * @summary POS - Generate Contract PDF
        * @request POST:/pdf/pos/generate-contract
        * @secure bearerAuth
        * @response `200` `DocumentResp` Ok
        * @response `400` `{
        error: "ERR_JSON_VALIDATION" | "ERR_CUSTOMER_DETAILS_NOT_CONFIRMED",
             }`
        * @response `404` `{
        error: "ERR_TEMPLATE_ID_NOT_FOUND" | "ERR_CHECKOUT_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        generateContract: (data, params = {}) => this.http.request("POST", `${this.url}/pdf/pos/generate-contract`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name GeneratePoa
        * @summary POS - Generate POA PDF
        * @request POST:/pdf/pos/generate-poa
        * @secure bearerAuth
        * @response `200` `DocumentResp` Ok
        * @response `400` `{
        error: "ERR_JSON_VALIDATION" | "ERR_CUSTOMER_DETAILS_NOT_CONFIRMED",
             }`
        * @response `404` `{
        error: "ERR_TEMPLATE_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        generatePoa: (data, params = {}) => this.http.request("POST", `${this.url}/pdf/pos/generate-poa`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  PdfService.Api = Api;
})(PdfService = exports.PdfService || (exports.PdfService = {}));