"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CaptureInfoService = void 0;
const Interfaces_1 = require("../Interfaces");
var CaptureInfoService;
(function (CaptureInfoService) {
  /**
   * @title YOL Service
   * @version 1.0.106
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL CAPTURE INFO SERVICE
   */
  class Api {
    constructor(url, http) {
      this.public = {
        /**
         * No description
         *
         * @tags public
         * @name SubmitContestDetails
         * @summary This service collects contest data and saves it in db
         * @request POST:/capture-info-nj/submit-contest-details
         * @response `204` `void` No content
         * @response `400` `HttpResponseError`
         * @response `401` `HttpResponseError`
         * @response `404` `HttpResponseError`
         * @response `500` `HttpResponseError`
         */
        submitContestDetails: (data, params = {}) => this.http.request("POST", `${this.url}/capture-info-nj/submit-contest-details`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
         * No description
         *
         * @tags public
         * @name SubmitLinecheckDetails
         * @summary This service collects line check data and saves it in db
         * @request POST:/capture-info-nj/submit-linecheck-details
         * @response `204` `void` No content
         * @response `400` `HttpResponseError`
         * @response `401` `HttpResponseError`
         * @response `404` `HttpResponseError`
         * @response `500` `HttpResponseError`
         */
        submitLinecheckDetails: (data, params = {}) => this.http.request("POST", `${this.url}/capture-info-nj/submit-linecheck-details`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
         * No description
         *
         * @tags public
         * @name SubmitOrderDetails
         * @summary This service collects order data and saves it in db
         * @request POST:/capture-info-nj/submit-order-details
         * @response `204` `void` No content
         * @response `400` `HttpResponseError`
         * @response `401` `HttpResponseError`
         * @response `404` `HttpResponseError`
         * @response `500` `HttpResponseError`
         */
        submitOrderDetails: (data, params = {}) => this.http.request("POST", `${this.url}/capture-info-nj/submit-order-details`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
         * No description
         *
         * @tags public
         * @name Submit2GUpgradeDetails
         * @summary This service collects 2g sim upgrade data and saves it in db
         * @request POST:/capture-info-nj/submit-2g-upgrade-details
         * @response `204` `void` No content
         * @response `400` `HttpResponseError`
         * @response `401` `HttpResponseError`
         * @response `404` `HttpResponseError`
         * @response `500` `HttpResponseError`
         */
        submit2GUpgradeDetails: (data, params = {}) => this.http.request("POST", `${this.url}/capture-info-nj/submit-2g-upgrade-details`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  CaptureInfoService.Api = Api;
})(CaptureInfoService = exports.CaptureInfoService || (exports.CaptureInfoService = {}));