export const urlify = (string: string) => {
  if (!string) return string;
  return string
    .toLowerCase()
    .split(' ')
    .join('_')
    .split('!')
    .join('')
    .split('"')
    .join('')
    .split("'")
    .join('')
    .split('.')
    .join('')
    .trim();
};
